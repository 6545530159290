<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <div v-if="message" class="alert alert-info mb-10" role="alert">
            {{ message }}
          </div>

          <BaseTable
            :columns="columns"
            :service="`esign-service/request/${$route.params.requestId}/job`"
            ref="table"
            @actionButton="handleAction"
            @selectedRows="handleSelected"
          >
            <template #header-right>
              <div>
                <button class="btn btn-secondary" @click="handleActionBatch">
                  <!-- <i class="ki-outline ki-category fs-6"></i> -->
                  Run Ulang
                </button>
                <button class="btn btn-secondary">Download Source</button>
                <button class="btn btn-secondary">Download Result</button>
              </div>
            </template>
            <template #action-button="{ data }">
              <button
                class="btn btn-secondary btn-sm"
                @click="handleAction({ type: 'detail', data: data.value })"
              >
                Detail
              </button>
              <!-- <button
                type="button"
                class="btn btn-sm btn-success !py-1"
                @click="handleAction({ type: 'view', data: data.value })"
              >
                Edit
              </button>
              <button
                type="button"
                class="btn btn-sm btn-danger !py-1"
                @click="handleAction({ type: 'delete', data: data.value })"
              >
                Delete
              </button> -->
            </template>
          </BaseTable>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
import Service from "../../../services/base.service";
import BaseTable from "/src/components/BaseTable.vue";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      loading: false,
      message: "",
      columns: [
        { field: "id", title: "ID", isUnique: true, type: "string" },
        { field: "status", title: "Status" },
        // { field: "source_file", title: "Source" },
        // { field: "result_file", title: "Result" },
        { field: "timestamp", title: "Timestamp" },
        // { field: "createdAt", title: "Create At" },
        // { field: "updatedAt", title: "Updated At" },
        // { field: "actions", title: "Actions" },
      ],
    };
  },
  mounted() {
    // this.getListData();
  },
  methods: {
    handleAction(value) {
      switch (value.type) {
        case "create":
          this.$router.push({ name: "esign-service-create" });
          break;
        case "view":
          this.$router.push({
            name: "esign-service-update",
            params: { id: value.data.id },
          });
          break;
        case "delete":
          this.deleteConfim(value.data.id);
          break;
        case "detail":
          this.$router.push({
            name: "esign-service-detail",
            params: { id: value.data.id },
          });
          break;
        default:
          break;
      }
    },
    deleteConfim(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteAction(id);
          }
        });
    },
    async deleteAction(id) {
      try {
        const BaseService = new Service("esign-service");
        const { message } = await BaseService.deleteData(id);
        this.message = message;
        this.$refs.table.reload();
      } catch (error) {
        console.log(error);
      }
    },
    handleSelected(value) {
      console.log("selected", value);
    },
    handleActionBatch() {
      this.$refs.table.getSelectedRows();
    },
  },
};
</script>
